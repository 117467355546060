import netlifyIdentity from "netlify-identity-widget";

export async function sendNotification(title: string, body: string) {
  // get netlify user
  const email = netlifyIdentity.currentUser()?.email;
  if (email !== "pialamar@gmail.com") {
    return;
  }

  // if the app is open, don't send notification
  if (document.visibilityState === "visible") {
    return;
  }

  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
    return;
  }

  const registration = await navigator.serviceWorker.getRegistration();

  if (!registration) {
    console.log("Service worker not found");
    return;
  }

  if (Notification.permission === "denied") {
    console.log("User has blocked notifications");
    return;
  }

  if (Notification.permission === "default") {
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      console.log("User has blocked notifications");
      return;
    }
  }

  if (Notification.permission === "granted") {
    const options = {
      body,
    };

    registration.showNotification(title, options);
  }
}
