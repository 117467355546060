import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import netlifyIdentity from 'netlify-identity-widget';
import refreshUser from 'functions/refreshUser';


async function getLatestTemperatures() {
    await refreshUser();

    const authToken = "Bearer " + netlifyIdentity.currentUser()?.token?.access_token;
    const response = await fetch('/.netlify/functions/getLatestTemperatures', { headers: { Authorization: authToken } });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message);
    }

    return data;
}

function InfoBlock({children}: {children: React.ReactNode|React.ReactNode[]}) {
    return (
        <div className="w-full h-fit justify-center rounded-lg overflow-hidden">
            {children}
        </div>
    )
}

function TemperatureInfoBlock({temperature, location, icon}: {temperature: number, location: string, icon: IconProp}) {
    // format temperature to 2 decimal places
    temperature = Math.round(temperature * 100) / 100;

    return (
        <InfoBlock>
            <div className="flex flex-row bg-airForceBlue w-full max-h-24 container h-fit px-2 py-1 items-center">
                <div className="w-1/3 h-16 justify-start items-center flex">
                    <div className="pl-2 flex items-center justify-center">
                        <FontAwesomeIcon className="text-snow h-16 w-full max-h-16" icon={icon} />
                    </div>
                </div>
                <div className="flex w-2/3">
                    <div className="flex flex-col text-snow font-merriweather ml-6">
                        <span className="font-merriweather text-lg"> {location} </span>
                        <span className="font-merriweather text-xl"> {temperature} °C </span>
                    </div>

                </div>

            </div>
        </InfoBlock>
    )
}

function LoadingTemperatureInfoBlock() {
    return (
        <InfoBlock>
            <div className="w-full flex justify-center items-center bg-airForceBlue px-3 py-1">
                <FontAwesomeIcon className="text-snow h-16 animate-spin" icon={["fas", "spinner"]} />
            </div>
        </InfoBlock>
    )
}

export default function LatestTemperatures() {
    const { isLoading, error, data } = useQuery({ queryKey: ['latestTemperatures'], queryFn: getLatestTemperatures, refetchInterval: 1000 * 60 * 10 })

    if (isLoading) {
        return(
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
                <LoadingTemperatureInfoBlock />
                <LoadingTemperatureInfoBlock />
                <LoadingTemperatureInfoBlock />
            </div>
        )
    }

    if (error) {
        return <span> Error! </span>
    }

    return (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
            <TemperatureInfoBlock temperature={data.temperatures.outdoors} location="Vonku" icon={["fas", "tree"]} />
            <TemperatureInfoBlock temperature={data.temperatures.indoors} location="Vnútri" icon={["fas", "house"]} />
            <TemperatureInfoBlock temperature={data.temperatures.bathroom} location="Kúpeľňa" icon={["fas", "sink"]} />
        </div>
    )
}