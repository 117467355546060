import React from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';


async function getDayTemperatures(day: string) {
    const authToken = "Bearer " + netlifyIdentity.currentUser()?.token?.access_token;
    const response = await fetch('/.netlify/functions/temperatures/' + day, { headers: { Authorization: authToken } });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message);
    }

    return data;
}

function todayUTC() {
    const today = new Date();
    const beginningOfTodayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    return beginningOfTodayUTC;
}


function RangeSelector({date, setDate} : {date: Date, setDate: (date: Date) => void}) {
    return (
        <div className="w-full flex text-snow gap-1">
            <button className="text-md font-merriweather font-medium bg-airForceBlue px-2 rounded-l-lg flex items-center"
                onClick={() => setDate(new Date(date.getTime() - 24*3600*1000))}>
                <FontAwesomeIcon icon={['fas', 'caret-left']} className="w-6 h-6"/>
            </button>
            <div className="w-full flex justify-center items-center bg-airForceBlue">
                <span className="text-md font-merriweather font-medium w-fit"> {date.toISOString().split('T')[0]} </span>
            </div>

            <button className="text-md font-merriweather font-medium p-2 bg-airForceBlue flex items-center"
                onClick={() => setDate(todayUTC())}>
                <FontAwesomeIcon icon={['fas', 'calendar-alt']} className="lg:w-8 w-6 h-6"/>
            </button>
            <button className="text-md font-merriweather font-medium px-2 bg-airForceBlue rounded-r-lg flex items-center"
                onClick={() => setDate(new Date(date.getTime() + 24*3600*1000))}>
                <FontAwesomeIcon icon={['fas', 'caret-right']} className="w-6 h-6"/>
            </button>
        </div>
    )
}

function formatTime(t: number, date: Date) {
    return new Date(date.getTime() + t * 1000).toISOString().split('T')[1].split(':').slice(0, 2).join(":")
}

function TempGraph({date}: {date: Date}) {
  const dateStr = date.toISOString().split("T")[0];
  // console.log("datestr", dateStr, "iso", date.toISOString())
  const { isLoading, data } = useQuery({ queryKey: ["dayTemps", dateStr], queryFn: () => getDayTemperatures(dateStr) });

  if (isLoading) return <div>Loading...</div>;

  const tempData = data?.map((d: any) => ({
    time: (new Date(d.timestamp).getTime() - date.getTime()) / 1000,
    bathroom: d.bathroom,
    outside: d.outside,
    inside: d.inside,
  }));

  // console.log("tempdata", tempData);

  return (
    <div className="w-full h-full touch-none">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={tempData} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
          <XAxis
            dataKey="time"
            tickFormatter={(t) => formatTime(t, date)}
            type="number"
            domain={[0, 24 * 3600]}
            ticks={[0, 6 * 3600, 12 * 3600, 18 * 3600, 24 * 3600]}
          />
          <YAxis width={25} type="number" />
          <Tooltip
            formatter={(value, name, props) => value + "°C"}
            labelFormatter={(label) => formatTime(label, date)}
            itemSorter={(item) => -(item.value as number)}
          />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          <Line
            type="monotone"
            dataKey="bathroom"
            stroke="#90C2E7"
            yAxisId={0}
            legendType="square"
            dot={false}
            strokeWidth={2}
            name="Kúpeľňa"
          />
          <Line
            type="monotone"
            dataKey="outside"
            stroke="#A31621"
            yAxisId={0}
            legendType="square"
            dot={false}
            strokeWidth={2}
            name="Vonku"
          />
          <Line
            type="monotone"
            dataKey="inside"
            stroke="#4E8098"
            yAxisId={0}
            legendType="square"
            dot={false}
            strokeWidth={2}
            name="Vnútri"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}


export default function TemperatureGraph() {
    const beginningOfTodayUTC = todayUTC();

    const [date, setDate] = React.useState(beginningOfTodayUTC);

    return (
        <div className="flex flex-col w-full h-full items-center justify-center relative gap-4">
            <RangeSelector date={date} setDate={setDate} />
            <TempGraph date={date} />
        </div>
    )
}