import netlifyIdentity from 'netlify-identity-widget';

export default async function refreshUser() {
    const currentUser = netlifyIdentity.currentUser();

    if (!currentUser) {
        return;
    }

    // const expiresIn = currentUser.token?.expires_in;
    // if (expiresIn && expiresIn < 600) {
        await netlifyIdentity.refresh();
    // }
}