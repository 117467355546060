import React, { useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import Account from './pages/Account';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fas, far);

const netlifyAuth = {
  user: null as any,
  authenticate(callback: any) {
    this.user = netlifyIdentity.currentUser();
    if (this.user !== null) {
      callback(this.user);
    } else {
      netlifyIdentity.open();
      netlifyIdentity.on('login', (user: any) => {
        this.user = user;
        netlifyIdentity.close();
        callback(user);
      });
    }
  },
  signout(callback: any) {
    netlifyIdentity.logout();
    netlifyIdentity.on('logout', () => {
      this.user = null;
      callback();
    });
  }
};

function LoginPage() {
  return (
    <div>
      <LoginButton />
    </div>
  )
}

function ProtectedRoute({ children }: { children: React.ReactNode|React.ReactNode[]}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!netlifyIdentity.currentUser()) {
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  if (!netlifyIdentity.currentUser()) {
    return null;
  }


  return (
    <>
      {children}
    </>
  );
};

function LoginButton() {
  const navigate = useNavigate();
  function loginF() {
    netlifyAuth.authenticate(() => {
      navigate('/', { replace: true });
    })
  };

  return (
    <button onClick={() => {loginF()}} className="text-lg bg-blue-400 rounded-lg px-3 py-2 m-3">Prihlasit sa</button>
  )
}

const queryClient = new QueryClient()

export default function AppWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <App/>
      </Router>
    </QueryClientProvider>
  )
}

function App() {
  const navigate = useNavigate();
  console.log(netlifyIdentity.currentUser()?.user_metadata);
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Account
              logout={() => {
                netlifyAuth.signout(() => {
                  navigate("/login", { replace: true });
                });
              }}
              userEmail={netlifyIdentity.currentUser()?.email}
            />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

