import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import netlifyIdentity from "netlify-identity-widget";
import refreshUser from "functions/refreshUser";
import { sendNotification } from "functions/notifications";

async function getLatestStatus() {
  await refreshUser();

  const authToken = "Bearer " + netlifyIdentity.currentUser()?.token?.access_token;
  const response = await fetch("/.netlify/functions/getLatestStatus", { headers: { Authorization: authToken } });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
}

async function setArmedState(armed: boolean) {
  const authToken = "Bearer " + netlifyIdentity.currentUser()?.token?.access_token;
  const response = await fetch("/.netlify/functions/setArmState", {
    method: "POST",
    body: JSON.stringify({ armed: armed }),
    headers: { Authorization: authToken, "content-type": "application/json" },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
}

function AlarmGeneric({
  children,
  color,
  isRefetching = false,
  onClick = () => {},
}: {
  children: React.ReactNode | React.ReactNode[];
  color: string;
  isRefetching?: boolean;
  onClick?: () => void;
}) {
  let refetchingClassName;
  if (isRefetching) {
    refetchingClassName = "";
  } else {
    refetchingClassName = "hidden";
  }

  return (
    <button onClick={() => onClick()} className="flex w-full h-full items-center justify-center relative">
      <div className={`absolute right-2 top-2 ${refetchingClassName}`}>
        <FontAwesomeIcon icon={["fas", "spinner"]} className="w-6 h-6 animate-spin text-frenchGray" />
      </div>
      <div className="flex flex-row rounded-lg bg-airForceBlue px-3 py-1 lg:w-1/2 w-full">
        <div className="w-1/3 flex items-center">
          <FontAwesomeIcon icon={["fas", "shield-cat"]} className={`w-full h-24 text-${color}`} />
        </div>
        <div className="w-full flex items-center justify-center">{children}</div>
      </div>
    </button>
  );
}

interface ElementsDict {
  [key: string]: {
    state: string;
    armed: boolean;
  };
}

function AlarmOn({
  elements,
  isRefetching = false,
  setDisarmed = () => {},
}: {
  elements: ElementsDict;
  isRefetching?: boolean;
  setDisarmed?: () => void;
}) {
  let elementStatus = {
    door: "?",
    backWindow: "?",
    frontWindow: "?",
  };

  let elementColor = {
    door: "snow",
    backWindow: "snow",
    frontWindow: "snow",
  };

  let alarmEngaged = false;

  for (const element in elements) {
    let status = "?";
    let color = "snow";

    if (elements[element].state === "closed") {
      status = "zatvorené";
    } else if (elements[element].state === "open") {
      if (elements[element].armed) {
        status = "otvorené";
        color = "madder";
        alarmEngaged = true;
      } else {
        status = "otvorené, siréna vypnutá";
      }
    }

    if (element === "door") {
      elementStatus.door = status;
      elementColor.door = color;
    }
    if (element === "back windows") {
      elementStatus.backWindow = status;
      elementColor.backWindow = color;
    }
    if (element === "front windows") {
      elementStatus.frontWindow = status;
      elementColor.frontWindow = color;
    }
  }

  let alarmColor = "lightGreen";
  if (alarmEngaged) {
    alarmColor = "madder";
  }

  return (
    <AlarmGeneric color={alarmColor} isRefetching={isRefetching} onClick={setDisarmed}>
      <div className="flex flex-col">
        <span className="text-lightGreen text-xl font-merriweather font-bold mb-1"> Alarm je zapnutý </span>
        <span className={`text-${elementColor.door} text-md font-merriweather font-medium`}>
          {" "}
          dvere: {elementStatus.door}{" "}
        </span>
        <span className={`text-${elementColor.frontWindow} text-md font-merriweather font-medium`}>
          {" "}
          predné okná: {elementStatus.frontWindow}{" "}
        </span>
        <span className={`text-${elementColor.backWindow} text-md font-merriweather font-medium`}>
          {" "}
          zadné okná: {elementStatus.backWindow}{" "}
        </span>
      </div>
    </AlarmGeneric>
  );
}

function AlarmLoading() {
  return (
    <AlarmGeneric color="snow">
      <FontAwesomeIcon icon={["fas", "spinner"]} className="w-full h-24 animate-spin text-snow" />
    </AlarmGeneric>
  );
}

function AlarmOff({ isRefetching = false, setArmed = () => {} }: { isRefetching?: boolean; setArmed?: () => void }) {
  return (
    <AlarmGeneric color="frenchGray" isRefetching={isRefetching} onClick={setArmed}>
      <span className="text-snow text-xl font-merriweather font-bold mb-1"> Alarm je vypnutý </span>
    </AlarmGeneric>
  );
}

export default function LatestStatus() {
  const { isLoading, error, data, isRefetching } = useQuery({
    queryKey: ["latestStatus"],
    queryFn: getLatestStatus,
    refetchInterval: 15000,
    refetchIntervalInBackground: true,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: setArmedState,
    onSuccess: (data, variables, context) => {
      // console.log("success", data);
      // console.log("variables", variables);
      queryClient.setQueryData(["latestStatus"], data);
      // queryClient.invalidateQueries(['latestStatus']);
    },
  });

  useEffect(() => {
    // send notification if the alarm armed status changed, but not by running toggleArmedState. The mutation
    // has to have happened on the server by someone else.
    if (data === undefined) {
      return;
    }

    if (data.armed) {
      sendNotification("Alarm zapnutý", "Alarm bol zapnutý");
    }
    if (!data.armed) {
      sendNotification("Alarm vypnutý", "Alarm bol vypnutý");
    }
  }, [data]);

  const toggleArmedState = () => {
    // console.log("setting", !data.armed)
    mutation.mutate(!data.armed);
  };

  if (isLoading || error || mutation.isLoading) {
    return <AlarmLoading />;
  }

  if (data.armed) {
    return <AlarmOn elements={data.elements} isRefetching={isRefetching} setDisarmed={toggleArmedState} />;
  } else {
    return <AlarmOff isRefetching={isRefetching} setArmed={toggleArmedState} />;
  }
}
