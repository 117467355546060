import React from 'react';
import LatestTemperatures from 'components/LatestTemperatures';
import LatestStatus from 'components/LatestStatus';
import TemperatureGraph from 'components/TemperatureGraph';
import { sendNotification } from "functions/notifications";

interface AccountProps {
  logout: () => void;
  userEmail?: string | null;
}

function NotificationsButton() {
  return (
    <button
      onClick={() => {
        sendNotification("Test notification", "Test notification body");
      }}
      className="font-merriweather font-medium text-snow text-lmd bg-airForceBlue"
    >
      Notifikácie
    </button>
  );
}

export default function Account({ logout, userEmail = null }: AccountProps) {
  return (
    <div className="w-screen h-full min-h-screen bg-snow">
      <header className="w-full h-12 bg-lightSkyBlue justify-center flex shadow-sm border-b-2 border-airForceBlue">
        <div className="flex flex-row lg:w-3/4 w-full px-8 justify-between items-center">
          <h1 className="font-merriweather font-medium text-snow text-xl drop-shadow-sm"> Tatry </h1>
          <button onClick={logout} className="font-merriweather font-medium text-snow text-lmd">
            Odhlásiť sa
          </button>
        </div>
      </header>

      <div className="w-full justify-center mt-2 lg:px-8 px-4 flex flex-col gap-8 mb-16">
        <LatestStatus />
        <LatestTemperatures />
        <TemperatureGraph />
        {userEmail === "pialamar@gmail.com" && <NotificationsButton />}
      </div>
    </div>
  );
}